import * as React from "react"
import { Col, Container, Row ,Form} from "react-bootstrap";


import Layout from "../components/layout"
import SEO from "../components/seo"
import background from "../images/banner3.jpg";
import {ImMobile } from 'react-icons/im'
import {AiOutlinePhone ,AiOutlineMail} from 'react-icons/ai'
import {FaHome} from 'react-icons/fa'
import { ArenguForm } from "gatsby-plugin-arengu-forms"

const FivePage = () => (
  <Layout>
    <SEO title="Kontakt" />
    <div className="background-img-about" style={{ backgroundImage: `url(${background})`}}>
      <div header-about-uns><h1 className="header-about-uns">swissartmaler</h1></div>
      </div>
      <Container className="kontakt">
          <Row>
              <Col id="col-kontakt-mob"xs={6} md={6} sm={12}>
                <h5 className="info">Kontaktinformation</h5>
                <div className="p-info">
                <p><ImMobile className="icon-kontakt"/>078 60 40 400</p>
                <p><AiOutlinePhone className="icon-kontakt"/>055 280 40 60</p>
                <p><AiOutlineMail  className="icon-kontakt"/>info@swissartmaler.ch</p>
                <p><FaHome className="icon-kontakt" />Im Zetrum 10, 8634 Hombrechtikon </p>
                </div>
              </Col>
              <Col id="col-kontakt-mob" xs={6} md={6} sm={12}>
                  <h5 className="info">Schrieben Sie Uns</h5>
                  <div><ArenguForm id="161883649216654325" /></div>
              </Col>
          </Row>
      </Container>
  </Layout>
)

export default FivePage
